import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./_style.scss"
function TabPanel(props) {
  const { children, value, index, handleChange, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  children,
  options,
  value,
  handleChange,
}) {

  return (
    <div className='tab_panel_wrapper'>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {options.map((op) => (
              <Tab label={op.label} {...a11yProps(op.value)} />
            ))}
          </Tabs>
        </Box>

        {options.map((op) => (
          <TabPanel value={value} index={op.value}>
            {children}
          </TabPanel>
        ))}
      </Box>
    </div>

  );
}
